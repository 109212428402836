import React from 'react'
import Layout from '../components/layout'
import image from '../images/blacktpheonix.png'

const About = () => (
  <Layout>
    <div>
      <h1 style={{ textAlign: 'center' }}>Salt & Light<br />in All of Life</h1>
      <img src={image} className="img-center" alt="Phoenix" />
      <p>HolyCulture was created to help disciples fulfill Jesus' words to be "Salt & Light" as individuals, as local church members, and as exiles in the world. The phoenix represents the death of Christ and the life of Christ, manifested wherever there is more and more death to sin and more and more life to righteousness.</p>
      <h2>Mission</h2>
      <p>To help God's holy ones lead holy lives.</p>
      <h2>Vision</h2>
      <p>To light up a dark world with winsome wisdom, penetrating truth, and persuasive power through Christian creativity.</p>
      <h2>Statement of Faith</h2>
      <p>HC's Faith is summarized in the <a href="https://www.opc.org/confessions.html" target="__blank">Westminster Confession of Faith and its catechisms.</a></p>
    </div>
  </Layout>
)

export default About
